import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [];

export const dictionary = {
		"/(welcome)": [16],
		"/admin/activity-log": [17,[3]],
		"/admin/flagged-conversations": [18,[3]],
		"/admin/rule-management": [19,[3]],
		"/admin/settings/ai-settings": [20,[3,4]],
		"/admin/settings/company-settings": [21,[3,4]],
		"/admin/settings/rule-settings": [22,[3,4]],
		"/admin/settings/tags-settings": [23,[3,4]],
		"/admin/usage-insights/costs": [24,[3,5]],
		"/admin/usage-insights/users": [25,[3,5]],
		"/admin/user-management": [26,[3]],
		"/app/chat": [27,[6]],
		"/app/digest": [28,[7]],
		"/app/settings/(settings)/data-feeds": [29,[8,9]],
		"/app/settings/(settings)/user-profile": [30,[8,9]],
		"/ari": [31],
		"/(login)/confirm-password-reset/[token]": [11,[2]],
		"/(login)/forgot-password": [12,[2]],
		"/josh": [32],
		"/(login)/login": [13,[2]],
		"/(login)/logout": [14,[2]],
		"/(login)/register": [15,[2]],
		"/share/chat/[uuid]": [33,[10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';